import React from 'react';
import styled from 'styled-components';
import { WIDTH, HEIGHT } from './constants';

const ImageBackgroundContainer = styled.img`
  position: absolute;
  top: 0;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  object-fit: cover;
  /* transition: 0.5s ease opacity; */
  z-index: 1;
`;

function ImageBackground(props) {
  const { src, visible } = props;
  return <ImageBackgroundContainer src={src} visible={visible} />;
}

export default ImageBackground;
