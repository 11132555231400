import styled from 'styled-components';
import posed from 'react-pose';

const WallProgress = styled.div`
  position: absolute;
  bottom: 264px;
  left: 240px;
  right: 240px;
`;

const WallProgressAnim = posed(WallProgress)({
  top: {
    y: -2086,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
  bottom: {
    y: 0,
    delay: 300,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
});

export default WallProgressAnim;
