import React, { useState, useEffect, useRef, useContext } from 'react';
import Player from 'react-player';
import styled from 'styled-components';
import { useKey } from 'react-use';

import Text from '../Text';
import { LogoImage } from '../Logo';
import { List, ListItem, ListSeparator } from '../List';
import WallContext from '../../contexts/WallContext';

const AuthContainer = styled.div(({ visible }) => {
  return `
    opacity: ${visible ? 1 : 0};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    transition: 1s ease all;
    transition-delay: 0.32s;
  `;
});

const AuthLogo = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  width: 1319px;
  height: 1674px;
  margin-top: -8px;
  margin-left: 1px;
  display: block;
  mask-image: url(${require('../../assets/n.svg')});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-border-mode: alpha;
  background: rgba(255, 255, 255, 0.08);
  z-index: 4;
  transition: 0.8s ease all;
  zoom: 1.06;
`;

const AuthSubmit = styled.button`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  cursor: pointer;
  padding: 64px 80px;
  color: #656565;
  font-size: 88px;
  background: #222;
  border: 0;
  border-radius: 24px;
  outline: 0;
  transition: 0.2s ease all;
  &:hover {
    background: #333;
  }
`;

const AuthForm = styled.form`
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  right: 260px;
  bottom: 280px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: 0.2s ease all;
`;

const AuthInput = styled.input`
  padding: 64px 80px;
  width: 1596px;
  height: 287px;
  font-size: 88px;
  color: #888;
  background: #121212;
  border: 0;
  border-radius: 24px;
  line-height: 88px;
  outline: none;
  transition: 0.2s ease all;
  &:focus {
    background: #1a1a1a;
  }
  &::placeholder {
    color: #656565;
  }
  &::selection {
    color: #999;
    background: #888;
  }
`;

const AuthFormError = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  margin-bottom: calc(287px / 2);
  color: #824747;
  font-size: 88px;
  transform: translateY(${({ visible }) => (visible ? 0 : 100)}px);
  transition: 0.3s ease all;
`;

const AuthGreeting = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  z-index: 4;
  width: 100%;
  padding-left: 240px;
  /* transform: translate3d(${({ visible }) => (visible ? 0 : 0)}px, 0, 0); */
  transition: 1s ease all;
  will-change: opacity;
`;

const AuthContinue = styled.button`
  position: absolute;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  cursor: pointer;
  padding: 0 120px;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  right: 260px;
  bottom: 280px;
  height: 287px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 88px;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  border-radius: 24px;
  outline: 0;
  transition: 0.2s ease all;
  z-index: 10;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

function Auth({ onDone, onData, doReset, active }) {
  const doContinue = useRef(false);
  const [authed, setAuthed] = useState(false);
  const [authError, setAuthError] = useState('');
  const [wallData, setWallData] = useState({});
  const [introDone, setIntroDone] = useState(false);
  const [cont, setCont] = useState(false);

  const data = useContext(WallContext);

  const usernameRef = useRef();
  const passwordRef = useRef();

  const shineRef = useRef();

  function reset() {
    setAuthed(false);
    setAuthError('');
    setWallData({});
    setIntroDone(false);
    setCont(false);
    doContinue.current = false;
    usernameRef.current.value = '';
    passwordRef.current.value = '';
  }

  function handleShineProgrees(progress) {
    if (!introDone && doContinue.current && progress.playedSeconds > 9.91) {
      setIntroDone(true);
    }
  }

  async function loadData(username, password) {
    const authRes = await fetch(
      'https://shift-review-pr-325.herokuapp.com/graphql/authenticate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          operationName: 'tokenAuth',
          variables: {
            app: 'video_wall',
            email: username, //'guide@sensei.com',
            password: password, //'passweird',
          },
          query: `
            mutation tokenAuth($email: String!, $password: String!, $app: String!) {
              tokenAuth(email: $email, password: $password, app: $app) {
                token
                refreshToken
                user {
                  id
                  firstName
                  lastName
                  roles
                }
              }
            }
        `,
        }),
      }
    );

    const authData = await authRes.json();
    if (authData.errors && authData.errors.length) {
      return authData;
    }
    const token = authData.data.tokenAuth.token;

    const wallRes = await fetch(
      'https://shift-review-pr-325.herokuapp.com/graphql',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          query: `
          {
            bookingParty(magicKey: "456954ae-ec79-4a21-a139-dbce4e1c2dff") {
              member {
                user {
                  firstName
                  lastName
                }
              }
              booking {
                startDate
                endDate
              }
              appointments {
                id
                startDate
                endDate 
                activity {
                  id
                  name
                  description
                  category {
                    id
                    name
                  }
                }
                location {
                  name
                }
              }
              guideForBooking {
                user {
                  firstName
                  lastName
                }
              }
              prearrival {
                preferredWakeTime
                mealTimes
                intentions
              }
            }
          }
          `,
        }),
      }
    );

    const wallData = await wallRes.json();
    return wallData;
  }

  async function handleAuth(e) {
    e.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    const res = await loadData(username, password);
    if (res.errors) {
      setAuthError(res.errors[0].message);
    } else {
      setWallData(res.data);
      if (onData) onData(res.data);
      setAuthed(true);
    }
  }

  useEffect(() => {
    if (doReset) {
      shineRef.current.seekTo(0, 'seconds');
      reset();
    }
  }, [doReset]);

  useEffect(() => {
    // Send over data sooner
    if (introDone) onDone(wallData);
  }, [introDone]);

  useKey(
    'ArrowRight',
    () => {
      if (active && authed) {
        setIntroDone(true);
        // setCont(true);
        // doContinue.current = true;
      }
    },
    {},
    [active, authed]
  );

  // useEffect(() => {
  //   async function auth() {
  //     const res = await loadData('guide@sensei.com', 'passweird');
  //     setWallData(res.data);
  //     setAuthed(true);
  //     onData(res.data);
  //     onDone();
  //   }
  //   auth();
  // }, []);

  return (
    <AuthContainer visible={!introDone}>
      <AuthForm onSubmit={handleAuth} visible={!authed} action="">
        <AuthFormError visible={authError !== ''}>{authError}</AuthFormError>
        <AuthInput
          required
          autoComplete="off"
          name="username"
          spellCheck="false"
          style={{ marginBottom: 287 / 2 }}
          // TODO: Should be guide email (?)
          placeholder="Guide ID"
          ref={usernameRef}
          onChange={() => setAuthError('')}
        />
        <AuthInput
          required
          autoComplete="off"
          name="password"
          type="password"
          placeholder="Password"
          ref={passwordRef}
          onChange={() => setAuthError('')}
        />
        <AuthSubmit style={{ display: 'none' }} />
      </AuthForm>

      <AuthContinue
        visible={authed && !cont}
        onClick={() => {
          setIntroDone(true);
          // doContinue.current = true;
          // setCont(true);
        }}
      >
        Continue
      </AuthContinue>

      <LogoImage style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }} />

      <AuthGreeting visible={!introDone && authed}>
        <Text size="h2" style={{ marginTop: 200 }}>
          <span style={{ fontWeight: 500 }}>Welcome</span>
        </Text>
        <Text size="h2" style={{ marginBottom: 48 }}>
          <span style={{ fontWeight: 500 }}>{data.guestName}</span>
        </Text>
        <List style={{ width: 'calc(25% - 480px)', marginTop: 0 }}>
          <ListSeparator style={{ background: 'rgba(255, 255, 255, 0.1) ' }} />
          <ListItem>
            <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Guide
            </Text>
            <Text size="p" style={{ color: '#fff' }}>
              {data ? data.guideName : ''}
            </Text>
          </ListItem>
          <ListItem>
            <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Start
            </Text>
            <Text size="p" style={{ color: '#fff' }}>
              {data && data.assessment ? data.assessment.start : ''}
            </Text>
          </ListItem>
          <ListItem>
            <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Room
            </Text>
            <Text size="p" style={{ color: '#fff' }}>
              {data && data.assessment ? data.assessment.location : ''}
            </Text>
          </ListItem>
        </List>
      </AuthGreeting>

      <AuthLogo visible={!authed} />

      <Player
        url={require('../../assets/video/shine.mp4')}
        muted
        loop={!introDone}
        width="100%"
        height="100%"
        style={{
          zIndex: 2,
        }}
        progressInterval={50}
        playing={authed}
        onProgress={handleShineProgrees}
        ref={shineRef}
      />
    </AuthContainer>
  );
}

export default Auth;
