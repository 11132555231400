import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useKey, useIdle } from 'react-use';
import posed, { PoseGroup } from 'react-pose';

import Auth from './components/Auth';
import Preloader from './components/Preloader';
import { ConsultationsWall, DayWall } from './components/Wall';

import { WIDTH, HEIGHT } from './components/constants';
import formatWallData from './helpers/formatter';
import WallContext from './contexts/WallContext';

import ThankYouWall from './components/Wall/ThankYouWall';

const WallContainer = styled.div`
  position: absolute;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  /* transition: 0.5s ease all; */
`;

const Item = posed.div({
  enter: {
    opacity: 1,
    delay: 3500,
    transition: { type: 'tween', duration: 2000, ease: 'easeOut' },
  },
  exit: { opacity: 0 },
});

// Routing logic here
function App() {
  const [step, setStep] = useState(0);
  const [wallData, setWallData] = useState({});
  const isIdle = useIdle(60e3 * 20);

  useKey('z', () => document.querySelector('html').classList.toggle('zoom')); // Toggles the 8K zoom

  useKey(
    't',
    () => {
      setStep(s => Math.max((s + 1) % 4, 1));
    },
    {},
    [step]
  );

  useEffect(() => {
    if (isIdle) {
      setStep(0);
      setWallData({});
    }
  }, [isIdle]);

  function handleAuthed(data) {
    if (step === 0) setStep(1);
  }

  function handleData(data) {
    console.log('API Data: ', data);
    setWallData(formatWallData(data));
  }

  return (
    <div className="wall">
      <WallContext.Provider value={wallData}>
        <Preloader visible={step === 0} />
        <WallContainer visible>
          <PoseGroup>
            {step === 0 ? (
              <Item
                key="auth-step"
                style={{ width: '100%', height: '100%', zIndex: 2 }}
              >
                <Auth
                  active={step === 0}
                  onDone={handleAuthed}
                  onData={handleData}
                  doReset={isIdle}
                />
              </Item>
            ) : null}
            {step === 1 ? (
              <Item
                key="consultations-step"
                style={{ width: '100%', height: '100%', zIndex: 2 }}
              >
                <ConsultationsWall
                  active={step === 1}
                  onEnd={() => setStep(2)}
                  doReset={isIdle}
                />
              </Item>
            ) : null}
            {step === 2 ? (
              <Item
                key="day-step"
                style={{ width: '100%', height: '100%', zIndex: 2 }}
              >
                <DayWall
                  active={step === 2}
                  onPrev={() => setStep(1)}
                  onEnd={() => setStep(3)}
                  doReset={isIdle}
                />
              </Item>
            ) : null}
            {step === 3 ? (
              <Item
                key="thankyou-step"
                style={{ width: '100%', height: '100%', zIndex: 2 }}
              >
                <ThankYouWall />
              </Item>
            ) : null}
          </PoseGroup>
        </WallContainer>
      </WallContext.Provider>
    </div>
  );
}

export default App;
