import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function useStopwatch(startFrom) {
  const [running, setRunning] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0); // in seconds

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        if (startFrom) {
          setElapsedTime(Math.round((new Date() - startFrom) / 1000));
        } else {
          setElapsedTime(prev => prev + 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [running, startFrom]);

  return {
    running,
    elapsedTime,
  };
}

const StopWatchContainer = styled.div`
  color: ${props => props.theme.StopWatch};
  position: absolute;
  right: 0;
  bottom: -24px;
  font-size: 64px;
  text-align: right;
  transition: 0.4s ease all;
`;

StopWatchContainer.defaultProps = {
  theme: {
    StopWatch: 'rgba(255, 255, 255, 0.7)',
  },
};

function Stopwatch({ startFrom }) {
  const { elapsedTime } = useStopwatch(startFrom);
  function pad(n) {
    const p = '00';
    return (p + n).slice(-p.length);
  }
  const minutes = pad(Math.floor(elapsedTime / 60));
  const seconds = pad(elapsedTime % 60);
  return (
    <StopWatchContainer>
      {minutes}:{seconds}
    </StopWatchContainer>
  );
}

export default Stopwatch;
