import styled from 'styled-components';
import posed from 'react-pose';

const DayBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 400px;
  height: 400px;
  border-radius: 280px;
  background-color: ${props => props.theme.DayBadge};
  transition: 0.4s ease background-color;
  transition-delay: 0.1s;
`;

const transition = {
  type: 'spring',
  damping: 13,
  // tension: 120,
};
export const DayBadge = posed(DayBadgeContainer)({
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition,
  },
  before: {
    opacity: 0,
    x: -100,
    scale: 1,
    transition,
  },
  after: {
    opacity: 0,
    x: 100,
    scale: 1,
    transition,
  },
  props: { i: 0 },
});

DayBadgeContainer.defaultProps = {
  theme: {
    DayBadge: '#fff',
  },
};

export const DayBadgeName = styled.div`
  margin-bottom: 16px;
  color: ${props => props.theme.DayBadgeName};
  font-size: 60px;
  transition: 0.4s ease color;
  transition-delay: 0.1s;
`;

DayBadgeName.defaultProps = {
  theme: {
    DayBadgeName: '#686868',
  },
};

export const DayBadgeNumber = styled.div`
  color: ${props => props.theme.DayBadgeNumber};
  font-size: 140px;
  transition: 0.4s ease color;
  transition-delay: 0.2s;
`;

DayBadgeNumber.defaultProps = {
  theme: {
    DayBadgeNumber: '#343434',
  },
};
