import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  pointer-events: 'none';
  position: absolute;
  width: 25%;
  height: 100%;
  z-index: 2;
`;

const SidebarLightBackground = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  transition: 0.4s ease all;
  transition-delay: 0.4s;
  z-index: -1;
`;

const SidebarDarkBackground = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.4s ease all;
  transition-delay: 0.4s;
  backdrop-filter: blur(60px);
  z-index: -1;
`;

const Sidebar = function({ dark, children }) {
  return (
    <SidebarContainer>
      {children}
      <SidebarDarkBackground visible={dark} />
      <SidebarLightBackground visible={!dark} />
    </SidebarContainer>
  );
};

export default Sidebar;
