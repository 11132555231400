import React, { useState, useEffect, useContext } from 'react';
import { useKey } from 'react-use';

import Logo from '../Logo';
import Dots from '../Dots';
import Sidebar from '../Sidebar';
import Stopwatch from '../Stopwatch';
import Title from '../Title'; // TODO: Rename this...
import Calendar from '../Calendar';
import WallProgress from './WallProgress';
import VideoBackground from '../VideoBackground';

import WallContext from '../../contexts/WallContext';

import {
  Intro,
  WhatIsSensei,
  WhatIsGuide,
  BloodPressure,
  BodyComposition,
  BodyScanner,
  Movement,
  VR,
  Meditation,
  YourStay,
  Nourishment,
  Rest,
  PAQ,
  MeditationComplete,
  StayMap,
} from '../Slides';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function ConsultationsWall({ onEnd, active, doReset }) {
  const [progress, setProgress] = useState(0); // Index starts at 0
  const data = useContext(WallContext);

  const slides = [
    // Assessment with...
    {
      chapter: '',
      component: Intro,
      type: 0,
    },
    // What is a guide?
    {
      chapter: 'Overview',
      component: WhatIsGuide,
      expanded: true,
      type: 0,
      opacity: 0.25,
    },
    // What is Sensei
    {
      chapter: 'Overview',
      component: WhatIsSensei,
      expanded: true,
      type: 0,
      opacity: 0.25,
    },
    // Blood pressure
    {
      chapter: '',
      component: BloodPressure,
      expanded: false,
      type: 0,
    },
    // Body composition
    {
      chapter: '',
      component: BodyComposition,
      expanded: false,
      type: 1,
    },

    // 3D body scanner
    {
      chapter: '',
      component: BodyScanner,
      expanded: false,
      type: 2,
    },
    // // Ready for VR
    {
      chapter: '',
      component: VR,
      expanded: false,
      type: 3,
      opacity: 0.6,
    },
    // // Meditation
    // {
    //   chapter: '',
    //   component: Meditation,
    //   expanded: true,
    //   type: 3,
    //   opacity: 0.8,
    // },
    // // Meditation complete
    // {
    //   chapter: '',
    //   component: MeditationComplete,
    //   expanded: false,
    //   type: 4,
    //   opacity: 0.5,
    // },

    // PAQ,
    {
      chapter: '',
      component: PAQ,
      extended: false,
      type: 2,
      opacity: 0.5,
    },
    // Lanai map
    {
      chapter: 'Your Stay',
      component: StayMap,
      expanded: true,
      type: 2,
      opacity: 0.5,
      hideTitle: true,
    },
    // Your stay
    {
      chapter: 'Your Stay',
      component: YourStay,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Movement
    {
      chapter: '',
      component: Movement,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Nourishment
    {
      chapter: '',
      component: Nourishment,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Rest
    {
      chapter: '',
      component: Rest,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Your stay
    {
      chapter: 'Your Stay',
      component: YourStay,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
  ];
  const totalSlides = slides.length;

  useKey(
    'ArrowLeft',
    () => {
      if (active) {
        setProgress(p => Math.max(--p, 0));
      }
    },
    {},
    [active]
  );

  useKey(
    'ArrowRight',
    () => {
      if (active) {
        setProgress(p => {
          if (p === totalSlides - 1) {
            if (onEnd) onEnd();
          }
          return Math.min(++p, totalSlides - 1);
        });
      }
    },
    {},
    [active]
  );

  const slide = slides[progress];

  // TODO: Fix highlights
  const calendarHighlightMap = {
    9: ['all'],
    10: ['Fitness'],
    11: ['Lunch', 'Dinner', 'Wellness'],
    12: ['Spa'],
    13: ['all'],
  };

  const calendarNames = [
    getDisplayName(Movement),
    getDisplayName(Nourishment),
    getDisplayName(Rest),
    getDisplayName(YourStay),
  ];

  useEffect(() => {
    if (doReset) setProgress(0);
  }, [doReset]);

  return (
    <>
      <Logo title={slide.chapter} />
      <Title
        pose={
          slide.hideTitle
            ? 'hidden'
            : slide.progressPosition !== 'top'
            ? 'visible'
            : 'hidden'
        }
      />
      <Sidebar
        visible={true}
        expanded={!active && progress === 0 ? 0 : slide.expanded}
        opacity={
          !active && progress === 0 ? 0 : slide.opacity ? slide.opacity : 0.25
        }
      >
        <>
          {slides.map((s, index) => (
            <s.component
              key={`consultation-slide-${index}`}
              pose={
                !active && progress === 0
                  ? 'before'
                  : progress === index
                  ? 'visible'
                  : progress < index
                  ? 'before'
                  : 'after'
              }
            />
          ))}
          <Calendar
            highlight={calendarHighlightMap[progress]}
            pose={
              calendarNames.includes(getDisplayName(slide.component))
                ? 'visible'
                : 'hidden'
            }
          />
        </>
        <WallProgress
          pose={
            !active
              ? 'hidden'
              : slide.progressPosition
              ? slide.progressPosition
              : 'bottom'
          }
        >
          <Dots progress={progress} total={totalSlides} />
          <Stopwatch startFrom={data.logInTimestamp} />
        </WallProgress>
      </Sidebar>
      <VideoBackground type={slide.type} />
    </>
  );
}

export default ConsultationsWall;
