import React from 'react';
import styled from 'styled-components';

import { WIDTH, HEIGHT } from './constants';

export const VideoBackgroundContainer = styled.video`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  top: 0;
  width: ${WIDTH}px;
  height: 100%;
  object-fit: cover;
  transition: 1.4s ease opacity;
  z-index: 0;
`;

const ImageBackgroundContainer = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* transition: 1.4s ease opacity; */
`;

function VideoBackground(props) {
  const { type = 0 } = props;
  return (
    <>
      <VideoBackgroundContainer autoPlay loop muted visible={type === 0}>
        <source src={require('../assets/video/h1.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer autoPlay loop muted visible={type === 1}>
        <source src={require('../assets/video/h2.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer autoPlay loop muted visible={type === 2}>
        <source src={require('../assets/video/h3.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer autoPlay loop muted visible={type === 3}>
        <source src={require('../assets/video/h4.mp4')} />
      </VideoBackgroundContainer>
      {/* <ImageBackgroundContainer
        visible={type === 4}
        style={{ backgroundImage: `url(${require('../assets/trees.jpg')})` }}
      /> */}
    </>
  );
}

export default VideoBackground;
