import React, { useContext } from 'react';

import WallContext from '../../contexts/WallContext';

import Logo from '../Logo';
import { VideoBackgroundContainer } from '../VideoBackground';
import Sidebar, { TopAlign } from '../Sidebar';
import Text from '../Text';
import { List, ListItem, ListSeparator, ListTitle } from '../List';

const ThankYouWall = () => {
  const pose = 'visible';
  const data = useContext(WallContext);
  return (
    <div className="wall">
      <Logo title={''} />
      <Sidebar visible={true} expanded={false} opacity={0.7}>
        <TopAlign pose={pose}>
          {/* <Avatar style={{ marginBottom: 48, backgroundColor: 'transparent' }} /> */}
          <Text size="h2" style={{ marginBottom: 48, marginTop: 640 }}>
            <strong>Assessment Complete</strong>
            <br />
            Grow Well.
          </Text>
          <List>
            <ListItem>
              <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                Guide
              </Text>
              <Text size="p" style={{ color: '#fff' }}>
                {data.guideName}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                Guest
              </Text>
              <Text size="p" style={{ color: '#fff' }}>
                {data.guestName}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                Stay
              </Text>
              <Text size="p" style={{ color: '#fff' }}>
                {data.stayStart} – {data.stayEnd}
              </Text>
            </ListItem>
          </List>
        </TopAlign>
      </Sidebar>
      <VideoBackgroundContainer autoPlay loop muted visible>
        <source src={require('../../assets/video/drone.mp4')} />
      </VideoBackgroundContainer>
    </div>
  );
};

export default ThankYouWall;
