import styled from 'styled-components';
import { WIDTH, HEIGHT } from '../constants';
import posed from 'react-pose';

const SingleImage = styled.div`
  position: absolute;
  left: 100%;
  right: 0;
  width: calc(${WIDTH}px - 100%);
  height: ${HEIGHT}px;
  object-fit: cover;
  will-change: width;
  background-image: url(${props => props.src});
  background-size: ${WIDTH - WIDTH * 0.25}px;
  /* background-repeat: no-repeat;
  background-size: ${WIDTH}px; */
`;

const transition = {
  // type: 'spring',
  duration: 800,
  ease: [0.77, 0, 0.175, 1],
  // flip: 1,
  // damping: 12,
  // stiffness: 80,
};

const SingleImageAnim = posed(SingleImage)({
  visible: {
    delay: 200,
    width: WIDTH - WIDTH * 0.25,
    // opacity: 1,
    transition,
  },
  before: {
    width: 0,
    // opacity: 0.001, // Load image into memory. Dumbest hack ever.
    transition,
  },
  after: {
    // opacity: 0,
    width: 0,
    transition,
  },
});

export default SingleImageAnim;
