import styled from 'styled-components';
import posed from 'react-pose';

import { WIDTH, HEIGHT } from '../constants';

const SmallGateContainer = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  display: flex;
`;
const SmallGateItem = styled.div`
  // padding: 1030px 240px 260px 240px;
  width: 25%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
`;
const SmallGateTitle = styled.div`
  position: absolute;
  padding-left: 240px;
  top: 1020px;
  color: #fff;
  font-size: 200px;
`;

const SmallGateFrame = styled.div`
  overflow: hidden;
  display: block;
  width: 100%;
  height: calc(100% - 400px);
`;
const SmallGateImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => (src ? src : '')});
  // background-size: 1790px;
  background-size: cover;
  background-position: left center;
  border: 0;
  outline: 0;
  will-change: width;
`;

const transition = {
  // type: 'spring',
  duration: 800,
  ease: [0.77, 0, 0.175, 1],
  // flip: 1,
  // damping: 12,
  // stiffness: 80,
};

const SmallGateContainerAnim = posed(SmallGateContainer)({
  visible: {
    delayChildren: 400,
    staggerChildren: 500,
    // transition,
  },
  before: {},
  after: {},
});

const SmallGateImageAnim = posed(SmallGateImage)({
  visible: {
    width: WIDTH / 4,
    transition,
  },
  before: {
    width: 0,
    transition,
  },
  after: {
    width: 0,
    transition,
  },
});

const SmallGateTitleAnim = posed(SmallGateTitle)({
  visible: {
    opacity: 1,
    transition,
  },
  before: {
    opacity: 0,
    transition,
  },
  after: {
    opacity: 0,
    transition,
  },
});

export {
  SmallGateContainerAnim as SmallGateContainer,
  SmallGateImageAnim as SmallGateImage,
  SmallGateItem,
  SmallGateTitleAnim as SmallGateTitle,
};
