import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import Text from './Text';
import WallContext from '../contexts/WallContext';

const TitleContainer = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  top: 320px;
  right: 240px;
  text-align: right;
  z-index: 2;
`;

const TitleForward = React.forwardRef((props, ref) => {
  const data = useContext(WallContext);
  return (
    <TitleContainer ref={ref} {...props}>
      <Text size="p">
        {data.guestName}
        <br />
        with {data.guideName}
      </Text>
    </TitleContainer>
  );
});

const transition = {
  type: 'spring',
};
const TitleAnim = posed(TitleForward)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  hidden: {
    opacity: 0,
    x: 100,
    transition,
  },
});

export default TitleAnim;
