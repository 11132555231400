import posed from 'react-pose';
import styled from 'styled-components';

const SIZES = {
  h1: {
    color: '#fff',
    fontSize: 200,
    lineHeight: 248,
  },
  h2: {
    color: '#fff',
    fontSize: 160,
    lineHeight: 208,
  },
  p: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 88,
    lineHeight: 96 * 1.4,
  },
};
export const Text = styled.p(({ color, size = 'h1' }) => {
  const base = `
    color: ${color ? color : SIZES[size].color};
    font-size: ${SIZES[size].fontSize}px;
    line-height: ${SIZES[size].lineHeight}px;
    transition-origin: left center;
  `;
  let extra;

  return `${base} ${extra}`;
});

const transition = {
  type: 'spring',
  damping: 13,
  // duration: 400,
  // ease: 'easeInOut',
};
const TextAnim = posed(Text)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  before: { opacity: 0, x: -100, transition },
  after: { opacity: 0, x: 100, transition },
});

export default TextAnim;
