import styled from 'styled-components';
import posed from 'react-pose';

const transition = {
  type: 'spring',
  damping: 13,
  // duration: 400,
  // ease: 'easeInOut',
};

const List = styled.div`
  margin-top: 120px;
`;

const ListItem = styled.div`
  margin-bottom: 64px;
  width: 100%;
  display: flex;
  font-size: 88px;
  & > :first-child {
    width: 25%;
  }
`;

const ListSeparatorContainer = styled.div`
  margin: 120px 0;
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
`;

const ListTitleContainer = styled.div`
  margin-bottom: 120px;
  color: #fff;
  font-size: 72px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2.8px;
`;

const ListSeparator = posed(ListSeparatorContainer)({
  visible: {
    opacity: 1,
  },
  before: {
    opacity: 0,
  },
  after: {
    opacity: 0,
  },
});

const ListTitle = posed(ListTitleContainer)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  before: { opacity: 0, x: -100, transition },
  after: { opacity: 0, x: 100, transition },
});

export { List, ListSeparator, ListItem, ListTitle };
