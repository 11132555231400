import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { DateTime } from 'luxon';

import { Text } from '../Text';
import WallContext from '../../contexts/WallContext';

const DayContainer = styled.div(({ index }) => {
  index += 1;
  return `
    position: absolute;
    top: ${index % 2 === 0 ? '0px' : '50%'};
    left: ${Math.floor(index / 2) * (1852 + 312)}px;
    display: flex;
    flex-direction: row;
  `;
});
const DayBadgeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 280px;
  height: 280px;
  border-radius: 280px;
  background: #fff;
`;
const DayBadgeName = styled.div`
  margin-top: -10px;
  margin-bottom: 5px;
  color: #686868;
  font-size: 44px;
`;
const DayBadgeNumber = styled.div`
  color: #343434;
  font-size: 122px;
`;
const DayListContainer = styled.div`
  padding-left: calc(280px + 128px);
`;
const DayListItem = styled.div`
  margin-bottom: 88px;
  display: flex;
  width: 1800px;
`;

const DayBadge = posed(DayBadgeContainer)({
  visible: {
    delay: 200,
    opacity: 1,
    scale: 1,
    transition: ({ i }) => ({
      type: 'spring',
      delay: 100 + i * 50,
      damping: 15,
    }),
  },
  hidden: {
    delay: 100,
    opacity: 0,
    scale: 0,
    transition: {
      type: 'tween',
      duration: 200,
    },
  },
  props: { i: 0 },
});

const DayList = posed(DayListContainer)({
  visible: {
    opacity: 1,
    x: 0,
    delay: 500,
    transition: {
      type: 'tween',
      duration: 300,
    },
  },
  hidden: {
    opacity: 0,
    delay: 0,
    transition: {
      type: 'tween',
      duration: 300,
    },
  },
});

function CalendarDay({ day, highlight = [], index, pose }) {
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  return (
    <DayContainer index={index}>
      <DayBadge pose={pose} i={index}>
        <DayBadgeName>{day.date.split(' ')[0]}</DayBadgeName>
        <DayBadgeNumber>{day.date.split(' ')[1]}</DayBadgeNumber>
      </DayBadge>
      <DayList pose={pose}>
        {day.events.map(e => {
          const event = e.activity.name;
          const time = DateTime.fromISO(e.startDate)
            .setLocale('en')
            .toFormat('h:mm a');
          // TODO: Depending on what highlight looks like, we change the line below
          const hl =
            highlight.includes(event) ||
            highlight.includes('all') ||
            highlight.includes(e.activity.category.name);
          const timeColor =
            highlight.includes(event) ||
            highlight.includes(e.activity.category.name)
              ? '#fff'
              : 'rgba(255, 255, 255, 0.3)';
          const eventColor = hl ? '#fff' : 'rgba(255, 255, 255, 0.3)';
          // const weight = hl ? '400' : '300';

          return (
            <DayListItem key={`DayListItem-${event}`}>
              <Text
                size="p"
                style={{
                  width: '20%',
                  marginRight: 128,
                  color: timeColor,
                  transition: '0.3s ease all',
                }}
                pose="visible"
              >
                {time.toLowerCase()}
              </Text>
              <Text
                size="p"
                style={{
                  color: eventColor,
                  width: '52%',
                  fontWeight: hl && !highlight.includes('all') ? 500 : 300,
                  transition: '0.2s ease color',
                }}
                pose="visible"
              >
                {event}
              </Text>
            </DayListItem>
          );
        })}
      </DayList>
    </DayContainer>
  );
}

const CalendarContainer = styled.div`
  position: absolute;
  left: 240px;
  top: 320px;
  right: 240px;
  bottom: 240px;
`;

// const days = Array(7)
//   .fill(0)
//   .map((n, i) => {
//     return {
//       date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * i),
//       events: [
//         'Sync with Sensei Guide',
//         'Breakfast',
//         'Exercise',
//         'Lunch',
//         'Tour of Sensei Farms',
//         'Dinner',
//         'Fireside Talk',
//       ],
//     };
//   });
const Calendar = React.forwardRef((props, ref) => {
  const data = useContext(WallContext);
  const days = data.days;
  if (!days) return;
  return (
    <CalendarContainer ref={ref} {...props}>
      {days.map((day, index) => {
        return (
          <CalendarDay
            pose={props.pose}
            day={day}
            index={index}
            highlight={props.highlight}
            key={`calendar-day-${index}`}
          />
        );
      })}
    </CalendarContainer>
  );
});

export default Calendar;
