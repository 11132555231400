import React from 'react';
import styled from 'styled-components';

const DotsContainer = styled.div(({ position }) => {
  const baseStyle = `
    display: flex;
    z-index: 1;
    overflow: hidden;
  `;
  return baseStyle;
});
const Dot = styled.div(({ active, done, expanded, theme }) => {
  if (expanded) {
    return `
      margin-right: 48px;
      width: 160px;
      height: 32px;
      border-radius: 32px;
      border: ${`4px solid ${theme.ProgressDot}`};
      // background: ${theme.ProgressBar};
      // transform: translateX(${active ? -32 : 0}px) scale(${
      active ? 0.9 : 1
    });
      transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    `;
  }
  return `
    margin-right: ${done ? 48 + 80 : 48}px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: ${
      active ? `4px solid transparent` : `4px solid ${theme.ProgressDot}`
    };
    background: ${active ? theme.ProgressBar : 'transparent'};
    // transform: translateX(${active ? -32 : 0}px) scale(${active ? 0.9 : 1});
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  `;
});

const Bar = styled.div(({ progress, total, theme }) => {
  return `
    position: absolute;
    top: 0;
    width: ${Math.max((progress / total) * 160 * total - 47, 0)}px;
    height: 32px;
    border-radius: 32px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.175, 0.885, 0.32, 1.275), 0.4s background ease;
    transition-delay: 0.3s;
    z-index: 2;
  `;
});

const InnerBar = styled.div(({ progress, active, visible, done, theme }) => {
  return `
    position: absolute;
    top: 0;
    // opacity: ${visible ? 1 : 0};
    width: ${!active ? 0 : done ? 0 : progress * 160}px;
    height: 32px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.77, 0, 0.175, 1), 0.4s background ease;
    z-index: 2;
  `;
});

Bar.defaultProps = {
  theme: {
    ProgressBar: '#fff',
  },
};

Dot.defaultProps = {
  theme: {
    ProgressDot: 'rgba(255, 255, 255, 0.7)',
  },
};

function DotsMulti(props) {
  const {
    progress = 0,
    day = 0,
    dayProgress = 0,
    expanded = false,
    total = 10,
    position = 'bottom',
  } = props;

  return (
    <DotsContainer position={position}>
      <Bar progress={progress} total={total} />
      {Array.from(Array(total).keys()).map(i => {
        return (
          <Dot
            key={`dot-${i}`}
            day={day}
            dayProgress={day === i ? dayProgress : 0}
            expanded={day === i && expanded}
            active={progress >= i}
            done={day > i}
          >
            <InnerBar
              progress={dayProgress}
              visible={day === i && expanded}
              active={progress >= i}
              done={day > i}
            />
          </Dot>
        );
      })}
    </DotsContainer>
  );
}

export default DotsMulti;
