import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import load from 'load-asset';

const PreloadMessage = styled.div`
  position: absolute;
  opacity: ${props => (props.visible ? 1 : 0)};
  left: 240px;
  bottom: 280px;
  color: rgba(255, 255, 255, 0.15);
  font-size: 88px;
  z-index: 20;
  pointer-events: none;
  transition: 1s ease all;
  transform: translateX(${props => (props.visible ? 0 : -70)}px);
`;

const Preloader = function({ visible }) {
  const images = [require('../../assets/map.webp')];
  const [progress, setProgress] = useState({ percent: '0%', done: false });

  useEffect(() => {
    async function start() {
      try {
        let thingsToLoad = [];

        // Taxonomy assets
        const res = await fetch(`${process.env.REACT_APP_ASSET_SERVER}/list`, {
          method: 'GET',
        });
        const assets = await res.json();
        assets.forEach(x => {
          thingsToLoad.push(`${process.env.REACT_APP_ASSET_SERVER}${x.path}`);
        });

        // Consultations assets
        thingsToLoad.push(require('../../assets/video/h1.mp4'));
        thingsToLoad.push(require('../../assets/video/h2.mp4'));
        thingsToLoad.push(require('../../assets/video/h3.mp4'));
        thingsToLoad.push(require('../../assets/video/h4.mp4'));
        thingsToLoad.push(require('../../assets/video/shine.mp4'));
        thingsToLoad.push(require('../../assets/video/drone.mp4'));
        thingsToLoad.push(require('../../assets/calendar/move.png'));
        thingsToLoad.push(require('../../assets/calendar/nourish.png'));
        thingsToLoad.push(require('../../assets/calendar/rest.png'));

        thingsToLoad = thingsToLoad.map(x => {
          if (x.includes('mp4') || x.includes('webm')) {
            return { url: x, event: 'canplaythrough' };
          } else {
            return { url: x };
          }
        });

        console.log(thingsToLoad);
        const results = await load.all(thingsToLoad, data => {
          console.log('Loading:', Math.round(data.progress * 100) + '%');
          setProgress({
            percent: Math.round(data.progress * 100) + '%',
            done: false,
          });
          if (data.error) console.error(data.error);
        });

        setProgress({
          percent: '100%',
          done: true,
        });
        console.log('Following did not load: ', results.filter(x => !x));
        console.log('Done for now :)');
      } catch (e) {
        console.error(e);
      }
    }
    start();
  }, []);

  return <PreloadMessage visible={visible}>{progress.percent}</PreloadMessage>;
};

export default Preloader;
