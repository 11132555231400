import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import Text from '../Text';
import { List, ListItem, ListSeparator, ListTitle } from '../List';
import {
  SmallGateContainer,
  SmallGateItem,
  SmallGateTitle,
  SmallGateImage,
  SmallGateFrame,
} from '../Gate';
import SingleImage from '../SingleImage';
import { TopAlign, BottomAlign } from '../Sidebar';
import { DayBadge, DayBadgeName, DayBadgeNumber } from '../DayBadge';

import WallContext from '../../contexts/WallContext';
import { WIDTH } from '../constants';

// TODO: Polish components below

const AvatarContainer = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 400px;
  background: #fff;
`;

const transition = {
  type: 'spring',
  damping: 13,
  // damping: 20,
  // tension: 20,
};
const Avatar = posed(AvatarContainer)({
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition,
  },
  before: { opacity: 0, x: -100, scale: 1, transition },
  after: { opacity: 0, x: 100, scale: 1, transition },
});

function Intro({ pose }) {
  const data = useContext(WallContext);
  return (
    <TopAlign pose={pose}>
      <Avatar style={{ marginBottom: 48, backgroundColor: 'transparent' }} />
      <Text size="h2" style={{ marginBottom: 48 }}>
        <strong>Assessment</strong>
        <br />
        with {data.guideName}
      </Text>
      <List>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Guest
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.guestName}
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Stay
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.stayStart} – {data.stayEnd}
          </Text>
        </ListItem>
        <ListSeparator />
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            00
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Overview
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            01
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Assessment
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            02
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Your Stay
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

function WhatIsSensei({ pose }) {
  return (
    <>
      <TopAlign pose={pose}>
        <Text
          size="h1"
          style={{ marginTop: 200, marginBottom: 150, fontWeight: 400 }}
        >
          What is Sensei?
        </Text>
        <Text size="p">
          We focus on three practices that help you get familiar and maintain a
          conversation with your body and mind{' '}
        </Text>
      </TopAlign>
      <SmallGateContainer pose={pose}>
        <SmallGateItem>
          <SmallGateTitle style={{ marginBottom: 200 }}>Move</SmallGateTitle>
          <SmallGateImage
            src={require('../../assets/move.jpg')}
            style={
              {
                // alignSelf: pose === 'after' ? 'flex-end' : 'flex-start',
                // backgroundPosition:
                //   pose === 'after' ? 'right center' : 'left center',
              }
            }
          />
        </SmallGateItem>
        <SmallGateItem>
          <SmallGateTitle style={{ marginBottom: 200 }}>Nourish</SmallGateTitle>
          <SmallGateImage
            src={require('../../assets/nourish.jpg')}
            // style={{
            //   alignSelf: pose === 'after' ? 'flex-end' : 'flex-start',
            //   backgroundPosition:
            //     pose === 'after' ? 'right center' : 'left center',
            // }}
          />
        </SmallGateItem>
        <SmallGateItem>
          <SmallGateTitle style={{ marginBottom: 200 }}>Rest</SmallGateTitle>
          <SmallGateImage
            src={require('../../assets/rest.jpg')}
            style={{
              backgroundSize: 'cover',
              // alignSelf: pose === 'after' ? 'flex-end' : 'flex-start',
              // backgroundPosition:
              //   pose === 'after' ? 'right center' : 'left center',
            }}
          />
        </SmallGateItem>
      </SmallGateContainer>
    </>
  );
}

function WhatIsGuide({ pose }) {
  const data = useContext(WallContext);

  return (
    <>
      <TopAlign pose={pose}>
        <Text
          size="h1"
          style={{ marginTop: 200, marginBottom: 48, fontWeight: 400 }}
        >
          What is a <br />
          Sensei Guide?
        </Text>
        <List>
          <ListItem>
            <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Guide
            </Text>
            <Text size="p" style={{ color: '#fff' }}>
              {data.guideName}
            </Text>
          </ListItem>
          <ListItem>
            <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Contact
            </Text>
            <Text size="p" style={{ color: '#fff', maxWidth: '70%' }}>
              Feel free to call your Sensei host at any time at XXX-XXX-XXXX or
              drop by the lobby.
              <br />
              <br />
              Your Guide will be in constant communication with the host.
            </Text>
          </ListItem>
        </List>
      </TopAlign>
      <SingleImage
        pose={pose}
        src={require('../../assets/guides.jpg')}
        style={{
          backgroundSize: WIDTH - WIDTH * 0.2,
        }}
      />
    </>
  );
}

function BloodPressure({ pose }) {
  return (
    <BottomAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
        <strong>Assessments</strong>
        <br />
        Blood Pressure
      </Text>
      <Text size="p">
        Blood pressure monitoring is essential to preventing heart disease and
        strokes.{' '}
      </Text>
    </BottomAlign>
  );
}

function BodyComposition({ pose }) {
  return (
    <BottomAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
        <strong>Assessments</strong>
        <br />
        Body Composition
      </Text>
      <Text size="p">
        Breaks down weight into body compartments, namely, Fat Mass, Fat-free
        Mass, Body Water and Skeletal Muscle Mass.{' '}
      </Text>
    </BottomAlign>
  );
}

function BodyScanner({ pose }) {
  return (
    <BottomAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
        <strong>Assessments</strong>
        <br />
        Functional Movement Screen
      </Text>
      <Text size="p">
        The FMS is a tool used to identify asymmetries which result in
        functional movement deficiencies.{' '}
      </Text>
    </BottomAlign>
  );
}

function VR({ pose }) {
  return (
    <BottomAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
        <strong>Assessments</strong>
        <br />
        Pitching Sync
      </Text>
      <Text size="p">
        A two to three line description of this assessment and it’s importance
        to the assessment.{' '}
      </Text>
    </BottomAlign>
  );
}

function Meditation({ pose }) {
  return (
    <BottomAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
        <strong>
          Meditation
          <br />
          In Progress
        </strong>
      </Text>
      <Text size="p">Insert timer here.</Text>
    </BottomAlign>
  );
}

function YourStay({ pose }) {
  const data = useContext(WallContext);

  return (
    <>
      <TopAlign pose={pose}>
        <Text size="h1" style={{ marginTop: 200 }}>
          <span style={{ fontWeight: 400 }}>Your Stay</span>
        </Text>
        <Text style={{ marginBottom: 48 }}>
          <span style={{ opacity: 0.4 }}>
            {data.stayStart} to {data.stayEnd}
          </span>
        </Text>
      </TopAlign>
    </>
  );
}

function Movement({ pose }) {
  return (
    <>
      <TopAlign pose={pose}>
        <Text>
          <img
            src={require('../../assets/calendar/move.png')}
            style={{
              top: -830,
              left: -940,
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
          <span style={{ fontWeight: 400 }}>Move</span>
        </Text>
        <Text size="p">
          Consistent, dynamic movement is how we effectively interact with our
          environment.
        </Text>
      </TopAlign>
    </>
  );
}

function Nourishment({ pose }) {
  return (
    <>
      <TopAlign pose={pose}>
        <Text>
          <img
            src={require('../../assets/calendar/nourish.png')}
            style={{
              top: -830,
              left: -940,
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
          <span style={{ fontWeight: 400 }}>Nourish</span>
        </Text>
        <Text size="p">
          What fuels us through nutrition and emotional connection.
        </Text>
      </TopAlign>
    </>
  );
}
function Rest({ pose }) {
  return (
    <>
      <TopAlign pose={pose}>
        <Text>
          <img
            src={require('../../assets/calendar/rest.png')}
            style={{
              top: -830,
              left: -940,
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text size="h1" style={{ marginTop: 200, marginBottom: 48 }}>
          <span style={{ fontWeight: 400 }}>Rest</span>
        </Text>
        <Text size="p">How we recover and grow.</Text>
      </TopAlign>
    </>
  );
}

function MeditationComplete({ pose }) {
  const data = useContext(WallContext);

  return (
    <TopAlign pose={pose}>
      <Avatar style={{ marginBottom: 48, background: 'transparent' }} />
      <Text size="h2" style={{ marginBottom: 48 }}>
        <strong>Meditation Complete</strong>
        <br />
        with {data.guideName}
      </Text>
      <List>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Scene
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Forest
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Start HR
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            120 bpm
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            End HR
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            88 bpm
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

function PAQ({ pose }) {
  const data = useContext(WallContext);
  return (
    <TopAlign pose={pose}>
      <Text size="h1" style={{ marginTop: 200 }}>
        <span style={{ fontWeight: 400 }}>Your Stay</span>
      </Text>
      <Text style={{ marginBottom: 48 }}>
        <span style={{ opacity: 0.4 }}>
          {data.stayStart} to {data.stayEnd}
        </span>
      </Text>
      <List style={{ marginTop: 240 }}>
        <ListTitle style={{ textTransform: 'uppercase' }}>
          From your pre-arrival questionnaire:
        </ListTitle>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Wake
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.wake}
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Meals
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.meals ? data.meals.join(', ') : ''}
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Intention
          </Text>
          <Text size="p" style={{ color: '#fff', textTransform: 'capitalize' }}>
            {data.intentions ? data.intentions.join(', ') : ''}
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

function StayMap({ pose }) {
  return (
    <>
      <TopAlign pose={pose}>
        <Text size="h1" style={{ marginTop: 200 }}>
          <span style={{ fontWeight: 400 }}>Lanai at Koele</span>
        </Text>
        <Text>
          <span style={{ opacity: 0.4 }}>Map</span>
        </Text>
        <List style={{ marginTop: 240 }}>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              00
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Lobby
            </Text>
          </ListItem>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              01
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Your Room
            </Text>
          </ListItem>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              02
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Restaurant
            </Text>
          </ListItem>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              03
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Hales
            </Text>
          </ListItem>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              04
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Fitness Pavillion
            </Text>
          </ListItem>
          <ListItem style={{ marginBottom: 96 }}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 96 }}
            >
              05
            </Text>
            <Text size="p" style={{ color: '#fff', fontSize: 96 }}>
              Pool
            </Text>
          </ListItem>
        </List>
      </TopAlign>
      <SingleImage
        pose={pose}
        src={require('../../assets/map2.webp')}
        style={{
          backgroundPosition: 'bottom left',
        }}
      />
    </>
  );
}

function AssessmentComplete({ pose }) {
  const data = useContext(WallContext);
  return (
    <TopAlign pose={pose}>
      <Avatar style={{ marginBottom: 48, backgroundColor: 'transparent' }} />
      <Text size="h2" style={{ marginBottom: 48 }}>
        <strong>Assessment</strong>
        <br />
        with {data.guideName}
      </Text>
      <List>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Guest
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.guestName}
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Stay
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            {data.stayStart} – {data.stayEnd}
          </Text>
        </ListItem>
        <ListSeparator />
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            00
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Overview
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            01
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Assessment
          </Text>
        </ListItem>
        <ListItem>
          <Text size="p" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            02
          </Text>
          <Text size="p" style={{ color: '#fff' }}>
            Your Stay
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

export {
  Intro,
  WhatIsSensei,
  WhatIsGuide,
  BloodPressure,
  BodyComposition,
  BodyScanner,
  Movement,
  VR,
  Meditation,
  YourStay,
  Nourishment,
  Rest,
  PAQ,
  MeditationComplete,
  StayMap,
  AssessmentComplete,
};
