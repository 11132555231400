import React from 'react';
import styled from 'styled-components';

const DotsContainer = styled.div(({ position }) => {
  const baseStyle = `
    display: flex;
    z-index: 1;
    overflow: hidden;
  `;
  return baseStyle;
});
const Dot = styled.div(({ active, index, theme }) => {
  return `
    margin-right: 48px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: ${
      active ? `4px solid transparent` : `4px solid ${theme.ProgressDot}`
    };
    background: ${active ? theme.ProgressBar : 'transparent'};
    transform: translateX(${active ? -32 : 0}px) scale(${active ? 0.9 : 1});
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  `;
});

const Bar = styled.div(({ progress, total, theme }) => {
  return `
    position: absolute;
    top: 0;
    width: ${((progress + 1) / total) * 80 * total - 48}px;
    height: 32px;
    border-radius: 32px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.77, 0, 0.175, 1), 0.4s background ease;
    z-index: 2;
  `;
});

Bar.defaultProps = {
  theme: {
    ProgressBar: '#fff',
  },
};

Dot.defaultProps = {
  theme: {
    ProgressDot: 'rgba(255, 255, 255, 0.7)',
  },
};

function Dots(props) {
  const { progress = 3, total = 10, position = 'bottom' } = props;
  return (
    <DotsContainer position={position}>
      <Bar progress={progress} total={total} />
      {Array.from(Array(total).keys()).map(i => {
        return <Dot key={`dot-${i}`} index={i} active={progress >= i} />;
      })}
    </DotsContainer>
  );
}

export default Dots;
