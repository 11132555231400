import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

const LogoTitle = styled.div`
  position: absolute;
  top: 310px;
  left: calc(240px + 320px + 128px);
  color: ${props => props.theme.LogoTitle};
  font-size: 88px;
  transition: 0.5s ease color;
  transition-delay: 0.3s;
  z-index: 3;
`;
const transition = {
  type: 'spring',
};
const LogoTitleAnim = posed(LogoTitle)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition,
  },
});

LogoTitle.defaultProps = {
  theme: {
    LogoTitle: '#fff',
  },
};

const LogoImage = styled.div`
  position: absolute;
  margin-top: 320px;
  margin-left: 240px;
  width: 324px;
  height: 68px;
  mask-image: url(${require('../assets/logo-white.svg')});
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: ${props => props.theme.LogoImage};
  transition: 0.4s ease background-color;
  transition-delay: 0.3s;
  z-index: 3;
`;

LogoImage.defaultProps = {
  theme: {
    LogoImage: '#fff',
  },
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
function Logo(props) {
  const { title } = props;
  const prevTitle = usePrevious(title);
  return (
    <>
      <LogoImage />
      <LogoTitleAnim pose={title !== '' ? 'visible' : 'hidden'}>
        {title !== '' ? title : prevTitle}
      </LogoTitleAnim>
    </>
  );
}

export default Logo;
export { LogoImage };
