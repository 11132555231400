import React, { useState, useContext, useEffect, useRef } from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import { useKey, useDebounce } from 'react-use';
import { ThemeProvider } from 'styled-components';
import { DateTime } from 'luxon';

import Text from '../Text';
import Logo from '../Logo';
import Sidebar from '../Sidebar/SidebarPerf';
import { List, ListItem } from '../List';
import ImageBackground from '../ImageBackground';
import { VideoBackgroundContainer } from '../VideoBackground';
import { DayBadge, DayBadgeName, DayBadgeNumber } from '../DayBadge';
import WallProgress from './WallProgress';
import Stopwatch from '../Stopwatch';
import WallContext from '../../contexts/WallContext';
import Dots from '../Dots';
import { WIDTH, HEIGHT } from '../constants';

// Minimap
import {
  MinimapContainer,
  MinimapItem,
  MinimapTime,
  MinimapEvent,
  MinimapBorder,
} from './Minimap';

const TempPosedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 240px;
`;
const TempPosed = posed(TempPosedContainer)({
  visible: {
    opacity: 1,
    staggerChildren: 10,
    delayChildren: 420,
  },
  before: { opacity: 0 },
  after: { opacity: 0 },
});

const AlignTop = styled.div`
  position: absolute;
  top: 800px;
  width: 25%;
  padding-left: 240px;
  z-index: 3;
`;

const light = {
  LogoImage: '#000',
  LogoTitle: '#000',

  SidebarOpacity: 1,
  SidebarBackground: '#fff',
  ProgressBar: '#000',
  ProgressDot: 'rgba(0, 0, 0, 0.7)',
  StopWatch: 'rgba(0, 0, 0, 0.7)',

  DayBadge: '#000',
  DayBadgeName: '#fff',
  DayBadgeNumber: '#fff',

  DayListColor: '#000',

  MinimapItem: 'rgba(0, 0, 0, 0.3)',
  MinimapItemActive: 'rgba(0, 0, 0, 1)',
};
const dark = {
  LogoImage: '#fff',
  LogoTitle: '#fff',

  SidebarOpacity: 0.7,
  SidebarBackground: '#000',
  ProgressBar: '#fff',
  ProgressDot: 'rgba(255, 255, 255, 0.7)',
  StopWatch: 'rgba(255, 255, 255, 0.7)',

  DayBadge: '#fff',
  DayBadgeName: '#686868',
  DayBadgeNumber: '#343434',

  DayListColor: 'rgba(255, 255, 255, 0.7)',

  MinimapItem: 'rgba(255, 255, 255, 0.2)',
  MinimapItemActive: 'rgba(255, 255, 255, 1)',
};

const BackgroundItem = posed.div({
  visible: {
    applyAtStart: {
      opacity: 1,
      width: 0,
      left: '25%',
      zIndex: 2,
    },
    width: 8640 - 8640 * 0.25,
    transition: {
      type: 'tween',
      duration: 600,
      ease: [0.77, 0, 0.175, 1],
      // damping: 12,
    },
  },
  hidden: {
    applyAtStart: {
      zIndex: 1,
    },
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 800,
      ease: [0.77, 0, 0.175, 1],
      // duration: 1500,
      // ease: [0.77, 0, 0.175, 1],
      // damping: 12,
    },
    // opacity: 0,
    // delay: 1200,
    // width: 0,
    // delay: 1000,
    // zIndex: -1,
    // width: 0,
  },
});

const ThemeFade = posed.div({
  light: {
    opacity: 1,
    transition: ({ from, to }) => ({
      type: 'keyframes',
      values: [from, 0, 0, to],
      times: [0, 0.25, 0.5, 1],
      duration: 1500,
    }),
  },
  dark: {
    opacity: 1,
    transition: ({ from, to }) => ({
      type: 'keyframes',
      values: [from, 0, 0, to],
      times: [0, 0.25, 0.5, 1],
      duration: 1500,
    }),
  },
});

function DayEvent({ event, pose }) {
  return (
    <TempPosed pose={pose}>
      <Text
        size="h2"
        style={{ marginTop: 160, marginBottom: 48, maxWidth: '80%' }}
      >
        <span style={{ fontWeight: 400 }}>{event.activity.name}</span>
      </Text>
      <Text size="p" style={{ marginBottom: 48, textTransform: 'lowercase' }}>
        <span style={{ opacity: 0.7 }}>
          {DateTime.fromISO(event.startDate)
            .setLocale('en')
            .toFormat('h:mm a')}{' '}
          -{' '}
          {DateTime.fromISO(event.endDate)
            .setLocale('en')
            .toFormat('h:mm a')}
        </span>
      </Text>
      <Text size="p" style={{ marginBottom: 64, whiteSpace: 'pre-wrap' }}>
        <span style={{ opacity: 0.7 }}>
          {event.location ? event.location.name : ''}
        </span>
      </Text>
    </TempPosed>
  );
}

function DayList({ events, pose }) {
  return (
    <TempPosed pose={pose}>
      <List style={{ marginTop: 160 }}>
        {events.map((e, i) => {
          return (
            <ListItem key={`${e.startDate}-${i}`}>
              <Text
                size="p"
                style={{
                  color: '#000',
                  textTransform: 'lowercase',
                }}
              >
                {DateTime.fromISO(e.startDate)
                  .setLocale('en')
                  .toFormat('h:mm a')}
              </Text>
              <Text size="p" style={{ color: '#000', maxWidth: '66%' }}>
                {e.activity.name}
              </Text>
            </ListItem>
          );
        })}
      </List>
    </TempPosed>
  );
}

function DayWall({ onEnd, onPrev, active, doReset }) {
  const [progress, setProgress] = useState(0); // Index starts at 0
  const [theme, setTheme] = useState(light);
  const [slides, setSlides] = useState([]);
  const [taxonomy, setTaxonomy] = useState([]);
  const [debouncedBg, setDebouncedBg] = useState('');

  const data = useContext(WallContext);
  const days = data.days;

  const totalSlides = slides.length;
  const currentSlide = slides[progress];

  useDebounce(
    () => {
      setDebouncedBg(currentSlide.background);
    },
    230,
    [currentSlide]
  );

  // Fetch taxonomy
  useEffect(() => {
    console.log(
      `Fetching asset list from ${process.env.REACT_APP_ASSET_SERVER}`
    );
    async function fetchTaxonomy() {
      try {
        const res = await fetch(`${process.env.REACT_APP_ASSET_SERVER}/list`, {
          method: 'GET',
        });
        const json = await res.json();
        setTaxonomy(json);
      } catch (e) {
        console.error(e);
      }
    }
    fetchTaxonomy();
  }, []);

  // Set up backgrounds
  useEffect(() => {
    if (taxonomy.length === 0) return;
    function findBackground(slide) {
      const type = slide.type;
      if (type === 'list') {
        const bgs = taxonomy.filter(x => x.type === 'other');
        return bgs[Math.floor(Math.random() * bgs.length)];
      }
      if (type === 'event') {
        let bgs = [];
        bgs = taxonomy.filter(
          x => x.type === 'activity' && x.id === slide.activityId
        );
        if (bgs.length > 0) return bgs[Math.floor(Math.random() * bgs.length)];

        bgs = taxonomy.filter(
          x => x.type === 'category' && x.id === slide.categoryId
        );
        if (bgs.length > 0) return bgs[Math.floor(Math.random() * bgs.length)];

        bgs = taxonomy.filter(x => x.type === 'other');
        return bgs[Math.floor(Math.random() * bgs.length)];
      }
    }
    if (days) {
      let s = [];
      days.forEach((day, i) => {
        let listSlideData = {
          type: 'list',
          day: i,
          badge: day.date,
          background: '',
          component: props => <DayList events={day.events} {...props} />,
        };

        // Set random background from taxonomy for list
        listSlideData.background = `${process.env.REACT_APP_ASSET_SERVER}${
          findBackground(listSlideData).path
        }`;

        s.push(listSlideData);
        day.events.forEach(event => {
          let daySlideData = {
            activityId: event.activity.id,
            categoryId: event.activity.category.id,
            name: event.activity.name,
            type: 'event',
            day: i,
            badge: DateTime.fromISO(event.startDate)
              .setLocale('en')
              .toFormat('EEE d'),
            date: new Date(event.startDate),
            background: '',
            component: props => <DayEvent event={event} {...props} />,
          };

          // Set background for activity from taxonomy
          daySlideData.background = `${process.env.REACT_APP_ASSET_SERVER}${
            findBackground(daySlideData).path
          }`;

          s.push(daySlideData);
        });
      });
      setSlides(s);
    }
  }, [data, days, taxonomy]);

  useEffect(() => {
    if (currentSlide && currentSlide.type === 'list') {
      setTheme(light);
    } else if (currentSlide) {
      setTheme(dark);
    }
  }, [currentSlide]);

  useKey(
    'ArrowLeft',
    () => {
      if (active) {
        setProgress(p => {
          if (p === 0) {
            if (onPrev) onPrev();
          }
          return Math.max(--p, 0);
        });
      }
    },
    {},
    [active, totalSlides, currentSlide]
  );

  useKey(
    'ArrowRight',
    () => {
      if (active) {
        setProgress(p => {
          if (p === totalSlides - 1) {
            if (onEnd) onEnd();
          }
          return Math.min(++p, totalSlides - 1);
        });
      }
    },
    {},
    [active, totalSlides]
  );

  useEffect(() => {
    if (doReset) setProgress(0);
  }, [doReset]);

  if (slides.length === 0 || !currentSlide || !days) return null;
  const uniqueBgs = new Set(slides.map(s => s.background));
  const isList = currentSlide.type !== 'list';

  return (
    <ThemeProvider theme={theme}>
      <div className="wall">
        <ThemeFade
          pose={currentSlide.type === 'list' ? 'light' : 'dark'}
          style={{ position: 'absolute', width: '100%', zIndex: 3 }}
        >
          <Logo title={'Your Stay'} />
          <AlignTop>
            <DayBadge>
              <DayBadgeName>{currentSlide.badge.split(' ')[0]}</DayBadgeName>
              <DayBadgeNumber>
                {currentSlide.badge.split(' ')[1]}
              </DayBadgeNumber>
            </DayBadge>
            <div style={{ position: 'relative', width: '100%' }}>
              {slides.map((s, index) => {
                const pose =
                  progress === index
                    ? 'visible'
                    : progress < index
                    ? 'before'
                    : 'after';
                return (
                  <s.component key={`daywall-slide-${index}`} pose={pose} />
                );
              })}
            </div>
          </AlignTop>
        </ThemeFade>

        <MinimapContainer visible={isList}>
          <MinimapBorder />
          {days[currentSlide.day].events.map((event, index) => {
            return (
              <MinimapItem
                key={`MinimapItem-${event.activity.name}-${index}`}
                active={currentSlide.name === event.activity.name}
              >
                <MinimapTime>
                  {DateTime.fromISO(event.startDate)
                    .setLocale('en')
                    .toFormat('h:mm a')}
                </MinimapTime>
                <MinimapEvent>{event.activity.name}</MinimapEvent>
              </MinimapItem>
            );
          })}
        </MinimapContainer>
        <WallProgress
          style={{ width: 'calc(25% - 480px)', bottom: 264, zIndex: 3 }}
        >
          <Dots progress={currentSlide.day} total={days ? days.length : 1} />
          <Stopwatch startFrom={data.logInTimestamp} />
        </WallProgress>
        <Sidebar dark={isList} />
        {/* <PoseGroup
          animateOnMount
          preEnterPose="before"
          style={{ position: 'relative', height: '100%', overflow: 'hidden' }}
        >
          {currentSlide.background.includes('mp4') ? (
            <BackgroundItem key={'videobackground-' + currentSlide.background}>
              <VideoBackgroundContainer visible>
                <source
                  src={currentSlide.background}
                  type={
                    currentSlide.background.includes('.mp4')
                      ? 'video/mp4'
                      : 'video/webm'
                  }
                />
              </VideoBackgroundContainer>
            </BackgroundItem>
          ) : (
            <BackgroundItem key={'imagebackground-' + currentSlide.background}>
              <ImageBackground src={currentSlide.background} visible />
            </BackgroundItem>
          )}
        </PoseGroup> */}
        {Array.from(uniqueBgs).map((bg, index) => {
          if (bg.includes('.mp4') || bg.includes('webm')) {
            return (
              <BackgroundItem
                key={'videobg' + bg}
                pose={debouncedBg === bg ? 'visible' : 'hidden'}
                style={{
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  height: HEIGHT,
                }}
              >
                <VideoBackgroundContainer loop muted autoPlay visible>
                  <source
                    src={bg}
                    type={bg.includes('.mp4') ? 'video/mp4' : 'video/webm'}
                  />
                </VideoBackgroundContainer>
              </BackgroundItem>
            );
          } else {
            return (
              <BackgroundItem
                key={'imagebg' + bg}
                pose={debouncedBg === bg ? 'visible' : 'hidden'}
                style={{
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  height: HEIGHT,
                }}
              >
                <ImageBackground key={bg} src={bg} visible />
              </BackgroundItem>
            );
          }
        })}
        <VideoBackgroundContainer autoPlay loop muted visible>
          <source src={require('../../assets/video/h1.mp4')} />
        </VideoBackgroundContainer>
      </div>
    </ThemeProvider>
  );
}

export default DayWall;
