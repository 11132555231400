import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { WIDTH, HEIGHT } from '../constants';

const SidebarContainer = styled.div(({ visible, transparent, expanded }) => {
  return `
    pointer-events: ${visible ? 'auto' : 'none'};
    position: absolute;
    width: 25%;
    height: 100%;
    z-index: 1;
  `;
});

const SidebarBackground = styled.div(({ expanded, opacity, theme }) => {
  return `
    opacity: ${opacity};
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${theme.SidebarBackground};
    transition: 0.4s ease all;
    will-change: background-color;
  `;
});

SidebarBackground.defaultProps = {
  theme: {
    SidebarBackground: '#000',
  },
};

function Sidebar(props) {
  const { visible = true, expanded, opacity = 0.25, children, ...rest } = props;

  const [spring, set, stop] = useSpring(() => ({
    position: 'relative',
    width: WIDTH,
    height: HEIGHT,
    transformOrigin: 'left center',
    transform: `scale3d(${expanded ? 1 : 0.25}, 1, 1)`,
    willChange: 'transform',
    zIndex: -1,
    config: {
      mass: 1,
      tension: 80,
      friction: 15,
      precision: 0.001,
      // duration: 0,
    },
  }));

  set({
    transform: `scale3d(${expanded === 0 ? 0.2 : expanded ? 1 : 0.25}, 1, 1)`,
  });

  return (
    <SidebarContainer {...rest}>
      {children}
      <animated.div style={spring}>
        <SidebarBackground expanded={expanded} opacity={opacity} />
      </animated.div>
    </SidebarContainer>
  );
}

const BottomAlignContainer = styled.div`
  position: absolute;
  left: 240px;
  right: 240px;
  bottom: 600px;
`;

const TopAlignContainer = styled.div`
  position: absolute;
  top: 800px;
  left: 240px;
  right: 240px;
  z-index: 2;
`;

const TopAlign = posed(TopAlignContainer)({
  visible: { opacity: 1, staggerChildren: 20, delayChildren: 400 },
  hidden: { opacity: 0 },
});

const BottomAlign = posed(BottomAlignContainer)({
  visible: { opacity: 1, staggerChildren: 40, delayChildren: 400 },
  hidden: { opacity: 0 },
});

export default Sidebar;
export { TopAlign, BottomAlign };
