import React from 'react';
import styled from 'styled-components';

export const MinimapContainer = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  padding-left: 240px;
  padding-right: 240px;
  width: 25%;
  bottom: 472px;
  transform-origin: left center;
  will-change: transform;
  transform: translateY(${props => (props.visible ? 0 : 60)}px);
  transition: ${props => (props.visible ? 0.5 : 0)}s ease all;
  transition-delay: ${props => (props.visible ? 0.5 : 0)}s;
  z-index: 3;
`;

export const MinimapBorder = styled.div`
  margin-bottom: 120px;
  width: 100%;
  border-top: 3px solid rgba(255, 255, 255, 0.15);
`;

export const MinimapItem = styled.div`
  margin-bottom: 50px;
  display: flex;
  font-size: 64px;
  color: ${props =>
    props.active ? props.theme.MinimapItemActive : props.theme.MinimapItem};

  &:last-child {
    margin-bottom: 0;
  }
  transition: 0.5s ease all;
`;

export const MinimapTime = styled.div`
  flex-shrink: 0;
  width: 23%;
  line-height: 1.4;
  text-transform: lowercase;
`;

export const MinimapEvent = styled.div`
  line-height: 1.4;
`;

function Minimap() {
  return (
    <MinimapContainer>
      <MinimapItem active={true}>
        <MinimapTime>8:00 am</MinimapTime>
        <MinimapEvent>Sensei Signature Massage</MinimapEvent>
      </MinimapItem>
      <MinimapItem>
        <MinimapTime>8:00 am</MinimapTime>
        <MinimapEvent>Manicure and Pedicure</MinimapEvent>
      </MinimapItem>
    </MinimapContainer>
  );
}

export default Minimap;
